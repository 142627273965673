import { Head } from '../components/Layout/Head'
import meta from '../config/meta'
import PropTypes from 'prop-types'

const propTypes = {
    removeDefaultMeta: PropTypes.bool
}

const Layout = options => Page => {
    // NOTE some page maybe call layout without options
    // We have to set default value
    options = options || {}
    const { removeDefaultMeta } = options
    const Wrapper = props => {
        return (
            <React.Fragment>
                <Head
                    meta={removeDefaultMeta !== true && meta['blankLayout']}
                />
                <React.Fragment>
                    <Page {...props} />
                </React.Fragment>
            </React.Fragment>
        )
    }

    // Enable getInitialProps
    if (Page.getInitialProps) {
        Wrapper.getInitialProps = Page.getInitialProps
    }

    return Wrapper
}

Layout.propTypes = propTypes

export default Layout
