import { useState } from 'react'
import Router from 'next/router'
import withLayout from '@/layouts/blank'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { setCookie } from 'nookies'
import fetch from '@/lib/fetch'
import { RemoveDomainFromURL } from '@/lib/string'
import css from '@/styles/pages/signin.scss'
import Button from '@/components/Common/Button'
import Field from '@/components/Form/Field'

const Page = ({ query }) => {
    const formSchema = yup.object().shape({
        email: yup
            .string()
            .email('กรุณากรอก Email ให้ถูกต้อง')
            .required('กรุณากรอก Email'),
        password: yup.string().required('กรุณากรอกรหัสผ่าน'),
    })
    const initialFormValues = {
        email: '',
        password: '',
    }

    const bgImg = {
        backgroundImage: "url('/bg.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }
    const [errorMsg, setErrorMsg] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const submit = async (values) => {
        setIsLoading(true)
        const { email, password } = values
        try {
            const { response, token } = await fetch.post(
                false,
                {},
                '/api/v3/login/',
                {
                    email,
                    password,
                }
            )
            if (response.status) {
                setCookie({}, 'cesto_token_2', token, { path: '/' })
                let uri = '/'
                if (query.redirect) uri = RemoveDomainFromURL(query.redirect)
                Router.push(uri)
            } else {
                document.querySelector('[name=password]').value = ''
                setErrorMsg(response.message)
            }
        } catch (error) {
            if (error.message === 'Failed to fetch') {
                setErrorMsg(
                    "Can't connect to server. Please check your connection"
                )
            } else {
                setErrorMsg('Something went wrong. Please try again')
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={css.signin} style={bgImg}>
            <div className={css.signin_container}>
                <div></div>
                <Formik
                    validationSchema={formSchema}
                    initialValues={initialFormValues}
                    onSubmit={submit}
                >
                    <Form>
                        <div className="card">
                            <div className="card-header border-bottom-0">
                                <img
                                    className={css.signin_logo}
                                    src="/logo/text_blue.png"
                                    alt="Estopolis Logo"
                                />
                            </div>
                            <div className="card-body">
                                <Field label="Email" name="email" />
                                <Field
                                    label="Password"
                                    name="password"
                                    type="password"
                                />
                                <p id="errorMsg" className="text-danger">
                                    {errorMsg}
                                </p>
                            </div>
                            <div className="card-footer border-top-0">
                                <Button
                                    className={`btn btn-primary`}
                                    type="submit"
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                >
                                    Sign In
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

Page.getInitialProps = ({ query }) => {
    return { query }
}

export default withLayout()(Page)
